.site_logo {
    width: 250px;
}

.navbar {
    box-shadow: 0 4px 16px rgb(205 209 218 / 40%);
}

.desktop_navbar a:first-child {
    color: #ea6680 !important;

}

.desktop_navbar a:nth-child(2) {
    color: #ba55d3 !important;
}

.desktop_navbar a:nth-child(3) {
    color: #007aff !important;
}

.desktop_navbar a:first-child:hover {
    border-bottom: 1px solid #ea6680 !important;
}

.desktop_navbar a:nth-child(2):hover {
    border-bottom: 1px solid #ba55d3 !important;
}

.desktop_navbar a:nth-child(3):hover {
    border-bottom: 1px solid #007aff !important;
}

.desktop_header .dropdown_wrapper {
    color: black !important;
}

.desktop_header .dropdown_wrapper:hover {
    cursor: pointer;
    border-bottom: 1px solid #000 !important;
}

.profile_logo {
    /* border-radius: 50%; */
    /* background-color: #ba55d3; */
    /* padding: 4px; */
    transition: all 300ms ease-out;

}

.profile_logo:hover {
    box-shadow: 0px 0px 2px 2px rgba(42, 86, 237, 0.75);
    transition: all 300ms ease-out;
    transform: scale(0.9);
    /* background-color: #f3f3f6;
    border-radius: 50%;
    padding: 4px; */
}

.user_dropdown_menu.dropdown-menu[data-bs-popper] {
    left: unset !important;
    right: 20% !important;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.user_dropdown_menu .dropdown-item {
    font-size: 15px;
}

.dropdown_wrapper .dropdown_section {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    padding: 20px;
    top: 100%;
    transform: translateY(20px);
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    z-index: 111;
    transition: 0.2s all;
}


.dropdown_wrapper:hover .dropdown_section {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
}

/* .active{
    border-bottom: 1px solid #43000d !important;
}  */

.dropdown_section .left_col a {
    display: block;
    text-decoration: none;
    font-size: 14px;
    color: #000 !important;
}

.dropdown_section .left_col a:hover {
    text-decoration-line: underline;
    text-underline-offset: 10px;
    border-bottom: none !important;
}

.icon_bg {
    position: relative;
    cursor: pointer;
    background-color: #fff;
    height: 35px;
    width: 35px;
    border-radius: 50%;
}

.icon_bg:hover {
    background-color: #F3F3F6;
}

.search_icon,
.bell_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.rightside_content .img_wrapper img {
    height: 96px;
    width: 182px;
    border: 1px solid rgba(205, 209, 218, .5);
    border-radius: 12px;
}

li::marker {
    font-size: 14px;
}

.dropdown_bottom_section li a.text_black {
    font-size: 14px;
    font-weight: bold;
    color: #000 !important;
    text-decoration: none;
}

.dropdown_bottom_section li a.text_black:hover {
    text-decoration-line: underline;
    text-underline-offset: 10px;
    border-bottom: none !important;
}



/* Search Icon starts */


/* Search Icon ends */


.google_btn {
    /* background-color: #007aff !important; */
    border-color: #007aff !important;
}

.profile_avatar {
    background-color: transparent !important;
    border: 0 !important;
}

.profile_avatar:focus {
    box-shadow: none !important;
}

.profile_avatar.btn {
    padding: 0 !important;
}

@media screen and (max-width: 991px) {
    .desktop_header {
        display: none !important;
        visibility: hidden;
        opacity: 0;
    }
}

.modal-content {
    padding: 25px;
}



/* ============================= Mobile Navbar ==============================*/

.site_logo_mbl {
    width: 75%;
}

.mobile_header .navbar-toggler {
    box-shadow: none !important;
    border: none !important;
    border-radius: 0 !important;
    line-height: 0 !important;
}

.nav_links_mbl a {
    color: #000;
}

.nav_links_mbl .dropdown_wrapper span {
    color: #000 !important;

}

.dropdown-menu {
    border: none !important;
}

.dropdown-item {
    font-size: 14px;
}

@media screen and (min-width: 992px) {
    .mobile_header {
        display: none !important;
        visibility: hidden;
        opacity: 0;
    }
}