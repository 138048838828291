/* .main__container {
    min-height: 836px;
} */

.navbar-wrapper {
    position: relative;
    background: #53746e;
    padding: 21px 30px;
    display: flex;
    flex-wrap: wrap;
    transition: all 0.5s cubic-bezier(0.4, -0.25, 0.25, 1.1);
}

.navbar-right li {
    list-style: none;
}

.navbar-right .fullscreen-btn {
    text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.15);
    font-size: 1.5rem;
}

.navbar-right .fa-icon {
    margin-right: 15px;
    color: #f3f5f7;
    font-size: 23px;
}

.body-wrapper {
    padding: 30px;
    transition: all 0.5s cubic-bezier(0.4, -0.25, 0.25, 1.1);
    /* height: 85vh; */
}

.body-wrapper .card-effect {
    background: #eac7c3;
    color: #53746e;
    padding: 17px 8px;
    border-radius: 3px;
}

.body-wrapper .card-effect .dashboard {
    color: #53746E;
    background-color: rgb(234, 199, 195);
    font-size: 1.3rem;
}

.body-wrapper .card-effect .dashboard b {
    font-weight: bolder;
}

.body-wrapper .card {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    border: none;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
    transition: all 0.3s ease;
}

.body-wrapper .card:hover {
    transform: scale(1.1);
}

.body-wrapper .card .widget {
    display: flex;
    align-items: center;
    transition: all 0.3s;
    padding: 30px 26px;
    border-radius: 13px;
    border-bottom: 9px solid #53746e;
}

.body-wrapper .card .widget .widget-icon {
    width: 70px;
    height: 70px;
    display: flex;
    align-self: flex-start;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.body-wrapper .card .widget .widget-icon .w-icon {
    color: #53746e;
    font-size: 64px;
}

.body-wrapper .card .widget .widget-content {
    width: 100%;
    padding-left: 15px;
}

.body-wrapper .card .widget .widget-content .text {
    color: #6c757d !important;
}

.body-wrapper .card .widget .widget-content .text-dark {
    color: #53746e !important;
    font-weight: 700;
}

.body-wrapper .card .widget .widget-content .btn {
    background: #e7c6c2;
    color: #53746e;
    font-weight: 600;
    margin-top: 6px;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
}

.body-wrapper .card .widget .widget-content .btn:focus {
    box-shadow: none;
}

.body-wrapper .card .widget .widget-content .btn:hover {
    color: black;
}


@media screen and (max-width: 576px) {
    .body-wrapper .card .widget .widget-icon {
        width: 40px;
        height: auto;
    }

    .widget-content .widget-title {
        font-size: 12px !important;
    }

    .body-wrapper .card .widget .widget-content .btn {
        font-size: 13px;
    }
}

@media screen and (max-width:768px) {
   .breadcrumb {
       width: 85%; 
   }
}