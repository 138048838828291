.social_media_icon button {
    background-color: transparent !important;
    border: none !important;
    border-radius: 8px;
    color: #a9aeba !important;

}

.social_media_icon button:nth-child(1):hover {
    background-color: #1875f2 !important;
    color: white !important;
}

.social_media_icon button:nth-child(2):hover {
    background-color: #00ba3a !important;
    color: white !important;
}

.social_media_icon button:nth-child(3):hover {
    background-color: #1aa0f2 !important;
    color: white !important;
}

.social_media_icon button:nth-child(4):hover {
    background: linear-gradient(39.47deg, #09f 6.82%, #a033ff 48.51%, #ff5280 75.25%, #ff7061 87.06%) !important;
    color: white !important;
}

.social_media_icon button:nth-child(5):hover {
    background: #cdd1da !important;
    color: white !important;
}


.custom__social {
    position: sticky;
    bottom: 40%;
    left: 0;
    transform: translate(-50%);
    width: 70px;
    margin-left: -60px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, .16);
    padding: 15px;
}


.custom__social .facebook {
    display: block;
    background: #1875f2;
    border-radius: 8px;
    color: #ffffff;
    margin-bottom: 10px;
}

.custom__social .twitter {
    display: block;
    background: #1aa0f2;
    border-radius: 8px;
    color: #ffffff;
    margin-bottom: 10px;
}

.custom__social .whatsapp {
    display: block;
    background: #00ba3a;
    border-radius: 8px;
    color: #ffffff;
    margin-bottom: 10px;
}

.custom__social .pinterest {
    display: block;
    background: #c10013;
    border-radius: 8px;
    color: #ffffff;
    margin-bottom: 10px;
}

.custom__social .telegram {
    display: block;
    background: #2aa4e0;
    border-radius: 8px;
    color: #ffffff;
    margin-bottom: 10px;
}

.comment_btn {
    background-color: rgb(42, 25, 25) !important;
    border: none !important;
}


@media screen and (min-width:992px) and (max-width:1199px) {
    .feedback_blog div {
        padding: 8px 50px !important;
    }
}

@media screen and (min-width:1200px) {
    .feedback_blog div {
        padding: 10px 75px !important
    }
}

@media screen and (max-width:1050px) {
    .custom__social {
        position: sticky;
        top: 0;
        left: 0;
        transform: translate(-70%);
        width: 55px;
        margin-left: -18px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 8px 16px rgb(0 0 0 / 16%);
        padding: 8px;
    }
}