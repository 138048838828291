.top-to-btm {
    position: relative;
}

.icon-position {
    position: fixed;
    bottom: 40px;
    right: 35px;
    z-index: 20;
}

.icon-style {
    background-color: #ffc40b;
    border: 2px solid #fff;
    border-radius: 8px;
    height: 45px;
    width: 45px;
    color: #000;
    cursor: pointer;
    animation: movebtn 3s cubic-bezier(0.61, 0.61, 1, 1) infinite;
    transition: all .3s cubic-bezier(0.4, 0, 1, 1);
}

.icon-style:hover {
    animation: none;
    background: #fff;
    color: #000;
    border-radius: 8px;
    border: 2px solid #000;
}

@keyframes movebtn {
    0% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(10px);
    }

    50% {
        transform: translateY(0px);
    }

    75% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0px);
    }
}