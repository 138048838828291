* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

h1 {
    font-size: 2rem !important;
}

.flex_center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex_center_start {
    display: flex;
    align-items: center;
    justify-content: start;
}

.flex_between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fs12 {
    font-size: 12px;
}

.fs16 {
    font-size: 16px;
}

.fs22 {
    font-size: 22px;
}

.fs25 {
    font-size: 25px;
}

.cursor_pointer {
    cursor: pointer;
}

.reaction_active {
    background-color: #bdd6f8 !important;
    /* color: #000; */
}

.feedback_blog .smile:hover {
    cursor: pointer;
    color: #00ba3a;
}

.feedback_blog .sad:hover {
    cursor: pointer;
    color: #ca2222;
}

.feedback_blog .star:hover {
    cursor: pointer;
    color: #ba55d3;
}

.feedback_blog .comment:hover {
    cursor: pointer;
    color: #22b1e1;
}