.setting:hover p {
    text-decoration: underline;
}

.setting:hover .setting_icon {
    transform: rotateZ(180deg);
    transition: all 0.4s ease;
    font-size: 1.2rem;
}

.tab_section div {
    padding-bottom: 10px;
    border-bottom: 1px solid transparent;

}

.tab_section div:hover {
    cursor: pointer;
    border-bottom: 1px solid black;
    padding-bottom: 10px;
}

.tab_active {
    font-weight: bold;
    border-bottom: 1px solid black;
}