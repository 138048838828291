a.card_img_wrapper {
    cursor: pointer;
}

a.card_img_wrapper img {
    min-height: 200px;
    transition: all .2s linear !important;
    border-radius: 15px !important;
}

a.card_img_wrapper img:hover {
    transform: scale(1.1) !important;
    overflow: hidden;
}

.blog_title:hover {
    color: rgb(1, 134, 89) !important;
}

.join_us {
    background: linear-gradient(137.94deg, #78bcff, #8045fd);
    border-radius: 12px;
}

.join_us .media_btn div:hover {
    cursor: pointer;
    background-color: hsla(0, 0%, 100%, .8) !important;
}

.stay_tuned {
    background: linear-gradient(134.57deg, #fb82fb, #fc8ebc .01%, #b500b5);
    border-radius: 12px;
}

.arrow_btn:hover {
    background: #f3f3f6 !important;
}

.arrow_btn.btn-primary:focus {
    box-shadow: none !important;
}

input:focus {
    box-shadow: none !important;
}

.stay_tuned .form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff !important;
    opacity: 1;
    /* Firefox */
}

.media_btn div {
    border-radius: 6px;
}

.profile_img_icon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: cornflowerblue;
    background-size: cover;
}

.profile_img_icon img {}

.comment_box {
    background-color: #f3f3f6;
    border-radius: 8px;
}

.infinite_scroll {}

.custom_pagination {}

.page-link {
    color: #000 !important;
}

.page-link:hover {
    background-color: #CC2121 !important;
    color: #fff !important;
}

.page-item:last-child .page-link,
.page-item:first-child .page-link {
    border-radius: 0 !important;
}

.page-item {
    margin: 0 2px !important;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff !important;
    background-color: #393939 !important;
    border-color: #393939 !important;
}

.page-link:focus {
    box-shadow: none !important;
}

@media screen and (max-width:320px) {
    .feedback div {
        padding: 8px 12px !important;
    }
}

@media screen and (min-width:375px) and (max-width:425px) {
    .feedback div {
        padding: 8px 22px !important;
    }
}

@media screen and (min-width:992px) and (max-width:1199px) {
    .feedback div {
        padding: 5px 12px !important;
    }
}

@media screen and (min-width: 1200px) {
    .media_btn div {
        padding: 0.5rem 2rem !important;
    }
}